<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { Icon } from "@iconify/vue2";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Giderler Listesi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Icon },
  data() {
    return {
      isBusy: false,
      isButton: false,
      title: "Gider Listesi",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Gider Listesi",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      fields: [
        { key: "type.name", label: "Gider", sortable: true },
        { key: "balance", label: "Tutar", sortable: true },
        { key: "bank_type.text", label: "Ödeme Yöntemi", sortable: true },
        { key: "author", label: "Kaydı Oluşturan", sortable: true },
        { key: "date", label: "Ödeme Tarihi", sortable: true },
        { key: "updated_at", label: "Güncellenme Tarihi", sortable: true },
        { key: "actions", label: "İşlemler" },
      ],
      values: {
        first_date:0,
        last_date:0,
      },
    };
  },
  methods: {
    getData(ctx, callback) {
      const params = "?page=" + ctx.currentPage;
      const data = {
        first_date: this.values.first_date,
        last_date: this.values.last_date
      }
      axios
        .post(process.env.VUE_APP_BASEURL + "/expense/get" + params, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const items = response.data.data;
            this.totalRows = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            callback(items);
          }
        })
        .catch((error) => {
          callback([]);
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
      this.isBusy = false;
      return;
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    edit(index) {
      this.$router.push(`/expense/edit/${index}`);
    },
    del(index) {
      this.isButton = true
      const api_url = process.env.VUE_APP_BASEURL + `/expense/${index}`;
      axios
        .delete(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$refs.dataTable.refresh();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isButton = false
        });
    },
    sorgula() {
      this.$refs.dataTable.refresh();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="sorgula">
              <div class="row">

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="first">Başlangıç Tarihi</label>
                    <input type="date" v-model="values.first_date" class="form-control">
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="last">Bitiş Tarihi</label>
                    <input type="date" v-model="values.last_date" class="form-control">
                  </div>
                </div>

                <div class="col-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Ara</button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 d-flex justify-content-between align-items-center mb-3">
                <h4 class="card-title m-0">Gider Listesi</h4>
                <router-link to="/expense/add" class="btn btn-primary" :disabled="isButton">
                  <Icon icon="lucide:plus-circle" class="mr-1" /> Gider Ekle
                </router-link>
              </div>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table ref="dataTable" :items="getData" :fields="fields" :per-page="10" :total-rows="totalRows"
                responsive="sm" :current-page="currentPage" :busy.sync="isBusy">
                <template #table-busy>
                  <div class="d-flex flex-column align-items-center justify-content-center gap-1 my-2">
                    <Icon icon="lucide:loader" class="spinner-rotate" :style="{ fontSize: '72px' }" />
                  </div>
                </template>
                <template #cell(updated_at)="data">
                  {{ data.item.updated_at | moment("DD.MM.YYYY HH:mm") }}
                </template>
                <template #cell(date)="data">
                  {{ data.item.updated_at | moment("DD.MM.YYYY") }}
                </template>
                <template v-slot:cell(balance)="data">
                  {{ data.item.balance + " " + data.item.currency.symbol }}
                </template>
                <template v-slot:cell(actions)="data">
                  <button @click="edit(data.item.id)" :disabled="isButton" class="btn btn-sm btn-secondary">
                    Düzenle
                  </button>
                  <button @click="del(data.item.id)" :disabled="isButton" class="btn btn-sm btn-danger ml-2">
                    Sil
                  </button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                      @change="onPageChange"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
